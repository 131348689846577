import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import styles from "./blog.module.css";

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <section className={styles.postsContainer}>
        <h1 className={styles.blogTitle}>Blog posts</h1>
        <h4 className={styles.postCount}>
          {data.allMarkdownRemark.totalCount} posts
        </h4>
        <ul className={styles.postList}>
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <li className={styles.postListItem} key={node.id}>
                <Link className={styles.postItemLink} to={node.fields.slug}>
                  <h3 className={styles.postTitle}>{node.frontmatter.title}</h3>
                  <span className={styles.postDate}>
                    {node.frontmatter.date}
                  </span>
                  <p className={styles.postExcerpt}>{node.excerpt}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </section>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
        }
      }
    }
  }
`;
